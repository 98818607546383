// Configuration file for application-specific settings
import { ApplicationConfiguration } from '@core/models';
import versions from './_versions';

export const config: ApplicationConfiguration = {
  apiURL: 'https://w0fmk4vv63.execute-api.us-east-1.amazonaws.com/dev/',
  appDescription: 'Family Planning Annual Report Data System',
  appOwner: 'Mathematica',
  appRoutes: {
    signIn: '/auth/signin',
    signOut: '/auth/signout',
  },
  appTitle: 'FPAR',
  aws: {
    clientId: '4fut6jl0ed348rj76p1lt5159c',
    region: 'us-east-1',
    userPoolId: 'us-east-1_6raJgvCqN',
  },
  debugMode: true,
  production: false,
  serviceWorker: false,
  staging: false,
  timeoutLength: 14,
  version: versions.version,
};
